import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

import { onResize, toggleFullscreen, updateRenderer } from './utils'

/* ================================ */
// TEXTURE
/* ================================ */

/* ================================ */
// FONT
/* ================================ */
const fontLoader = new FontLoader()

const createTextGeometry = (font) => {
  const textGeometry = new TextGeometry('Stay Tuned', {
    font: font,
    size: 1,
    height: 0.4,
    curveSegments: 4,
    bevelEnabled: true,
    bevelThickness: 0.02,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5
  })

  textGeometry.center()
  return textGeometry
}

fontLoader.load(
  'fonts/helvetiker_bold.typeface.json',
  (font) => {
    const material = new THREE.MeshNormalMaterial();

    const textGeometry = createTextGeometry(font)
    const text = new THREE.Mesh(textGeometry, material)
    scene.add(text)

    generateDonuts(160, material)
  },
  () => {
    console.log('font onProgress')
  },
  () => {
    console.log('font onError')
  }
)

/* ================================ */
// SCENE
/* ================================ */
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()

/* ================================ */
// MATERIALS
/* ================================ */

const ambientLight = new THREE.AmbientLight(0xffffff, .5)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, .5)
pointLight.position.set(2, 3, 4)
scene.add(pointLight)

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, .1, 100)
camera.position.z = 5
scene.add(camera)

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

const renderer = new THREE.WebGLRenderer({
  canvas: canvas
})
updateRenderer(renderer)

/* ================================ */
// BIND EVENTS
/* ================================ */
window.addEventListener('resize', () => onResize(camera, renderer))
window.addEventListener('dblclick', () => toggleFullscreen(canvas))

// random donut
const generateDonuts = (count, material) => {
  const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 48)

  for (let i = 0; i < count; i++) {
    const donut = new THREE.Mesh(donutGeometry, material)

    donut.position.x = (Math.random() - 0.5) * 10
    donut.position.y = (Math.random() - 0.5) * 10
    donut.position.z = (Math.random() - 0.5) * 10

    donut.rotation.x = Math.random() * Math.PI
    donut.rotation.y = Math.random() * Math.PI

    const scale = Math.random()
    donut.scale.set(scale, scale, scale)

    scene.add(donut)
  }
}

/* ================================ */
// ANIMATION
/* ================================ */
const tick = () => {

  // Update controls
  controls.update()

  // Render
  renderer.render(scene, camera)

  window.requestAnimationFrame(tick)
}

tick()
