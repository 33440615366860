/**
 * Initiate and update renderer on resize
 * @param renderer
 */
const updateRenderer = (renderer) => {
  if (renderer) {
    renderer.setSize(window.innerWidth, window.innerHeight)
    // set the maximum pixel ratio to 2
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }
}

/**
 * Toggle fullscreen
 * @param element
 */
const toggleFullscreen = (element) => {
  const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

  if (!fullscreenElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) { // for Safari
      element.webkitRequestFullscreen()
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) { // for Safari
      document.webkitExitFullscreen()
    }
  }
}

/**
 * Handle window resize
 * @param camera
 * @param renderer
 */
const onResize = (camera, renderer) => {
  // update camera
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  updateRenderer(renderer)
}

export {
  updateRenderer,
  toggleFullscreen,
  onResize,
}
